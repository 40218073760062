
import { computed, defineComponent, getCurrentInstance, onMounted, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import router from "@/router";
import QuotesService from "@/core/services/car/QuotesService";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import CustomerService from "@/core/services/car/CustomerService";
import UserService from "@/core/services/UserService";
import InvoiceService from "@/core/services/car/InvoiceService";
import { boolean } from "yup/lib/locale";
import Multiselect from "@vueform/multiselect";
import MasterService from "@/core/services/car/MasterService";
import moment from "moment";
import { notif, validation, getCurrentDate, roles, phoneNumberValidation,otherGenders,paymentMethodLabel } from "@/store/stateless/store";
import CustomerService from "@/core/services/car/CustomerService";
import InvoiceModal from "./InvoiceModal.vue";
import { Mutations } from "@/store/enums/StoreEnums";
import { common } from "@/store/stateless/common";

interface AddInvoiceBodyParam {
  policy_sales_agent_id: any;
  invoice_id: string;
  invoice_number: string;
  merchant_reference: string;
  original_price: string;
  amount: number;
  payment_type: string;
  order_description: string;
  language: string;
  expiry_date_time: string;
  is_amend: string;
  amend_type: any;
  invoice_doc: string;
  car_value: number;
  policy_end_date?: any;
  new_name?: any;
  new_email?: any;
  new_phone_country_code?: any;
  new_phone_number?: any;
  nationality?: any;
  dob?: any;
  first_driving_license_country?: any;
  driving_experience?: any;
  uae_driving_experience?: any;
  claimed_insurance?: any;
  no_claim_certificate?: any;
  claims?: any;
  current_policy_active?: any;
  fully_comprehensive?: any;
  third_party_liability?: any;
  existing_policy_expired?: any;
  personal_use: any;
  driver_name?: any;
  policy_start_date?: any;
  gender: any | null;
  reference_no:any;
  insurance_payment:any;
  is_split_payment:any;
  split_payment_installments_count:any;
  installments:any;
}
export default defineComponent({
  name: "Add-Quote",
  props: {},
  components: { Multiselect, InvoiceModal },
  data() {
    return {
      dob: "",
      policyBegin: "",
      policyEndDate: "",
      registrationDate: "",
      dlExperience: [
        "",
        "0-6 months",
        "6 - 12 months",
        "1 - 2 Years",
        "More than 2 Years"
      ],
      claim_certificate: [
        "",
        "No - I have no certificate",
        "Yes - 1 year with proof",
        "Yes - 2 years with proof",
        "Yes - 3 or more years with proof"
      ]
    };
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1900 },
        (value, index) => 2021 - index
      );
    }
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    let amendInvoiceInformation = ref<any>({
      policy_sales_agent: "",
      policy_sales_agent_id: "",
      invoice_id: "",
      merchant_reference: "",
      parent_merchant_reference: "",
      name: "",
      email: "",
      phone_number: "",
      order_description: ""
    });
    let addInvoiceBodyParam = ref<AddInvoiceBodyParam>({
      policy_sales_agent_id: -1,
      invoice_id: "",
      invoice_number: "",
      merchant_reference: "",
      original_price: "",
      amount: 0,
      payment_type: "",
      order_description: "",
      language: "en",
      expiry_date_time: "",
      is_amend: "1",
      amend_type: "1",
      invoice_doc: "",
      policy_end_date: "",
      new_name: "",
      new_email: "",
      new_phone_number: "",
      new_phone_country_code: "+961",
      dob: "",
      nationality: null,
      first_driving_license_country: null,
      driving_experience: 4,
      uae_driving_experience: 4,
      claimed_insurance: 2,
      no_claim_certificate: "1",
      claims: "",
      current_policy_active: 1,
      fully_comprehensive: 1,
      third_party_liability: 2,
      existing_policy_expired: 2,
      personal_use: 1,
      car_value: 0,
      driver_name: "",
      gender: null,
      policy_start_date: getCurrentDate.today(),
      reference_no:"",
      insurance_payment:"",
      is_split_payment:"0",
      split_payment_installments_count:"",
      installments:[],
    });

    interface SplitPaymentInstallmentsParam {
  installment_no          : string,
  installment_payment_date:string,
  installment_amount      : string,
  installment_cib_receipt : any
}


    let splitPaymentInstallmentsParam = ref<SplitPaymentInstallmentsParam>({
        installment_no          : "",
        installment_payment_date: "",
        installment_amount      : "0",
        installment_cib_receipt : ""
    });

    const rules = ref({
      amend_type: {
        show: false,
        message: "Please select amend type"
      },
      policy_sales_agent_id: {
        show: false,
        message: "Policy agent is required"
      },
      name: {
        show: false,
        message: "Please enter name"
      },
      email: {
        show: false,
        message: "Please enter email"
      },
      amount: {
        show: false,
        message: "Please enter amount"
      },
      language: {
        show: false,
        message: "Please select Language is required"
      },
      order_description: {
        show: false,
        message: "Please enter order description"
      },
      payment_type: {
        show: false,
        message: "Please select payment type"
      },
      expiry_date: {
        show: false,
        message: "Expiry date is mandatory for online"
      },
      policy_agent: {
        show: false,
        message: "Policy agent is required"
      },
      policy_end_date: {
        show: false,
        message: "Please select policy end date"
      },

      new_name: {
        show: false,
        message: "Please enter new customer name"
      },
      new_email: {
        show: false,
        message: "Please enter new customer email"
      },
      new_phone_number: {
        show: false,
        message: "Please enter new customer phone number",
        length_message: "Please enter at least 8 digits."
      },

      car_value: {
        show: false,
        message: "Please enter car value"
      },

      nationality: {
        show: false,
        message: "Please enter driver nationality"
      },
      dob: {
        show: false,
        message: "Please enter driver date of birth"
      },
      first_registration_date: {
        show: false,
        message: "First Registration Date is required"
      },
      first_driving_license_country: {
        show: false,
        message: "Please select first DL was issued in"
      },
      uae_driving_experience: {
        show: false,
        message: "Uae driving Experience is required"
      },
      driving_experience: {
        show: false,
        message: "Home Country Driving Experience is required"
      },

      driver_name: {
        show: false,
        message: "Please enter driver name"
      },
      file_data: {
        show: false,
        message: "Please upload file for COD"
      },
      existing_policy_expired: {
        show: false,
        message: "Please select policy active"
      },
      personal_use: {
        show: false,
        message: "Please select vehicle use"
      },
      third_party_liability: {
        show: false,
        message: "Please select existing policy TPL only "
      },
      policy_start_date: {
        show: false,
        message: "Please select Policy Start Date"
      },
      claimed_insurance: {
        show: false,
        message: "Please select claims made"
      },
      no_claim_certificate: {
        show: false,
        message: "Please No Claim Certificate status"
      },
      claims: {
        show: false,
        message: "Please enter claims."
      },
      gender: {
        show: false,
        message: "Please select gender "
      },
      reference_no: {
        show: false,
        message: "Please enter Reference No.",
      },
      insurance_payment: {
        show: false,
        message: "Please select insurance payment",
      },
      payment_date: {
        show: false,
        message: "Please select payment date",
      },
      amount_error: {
        show: false,
        message: "Incorrect amount"
      },
      is_split_payment: {
        show: false,
        message: "Select Yes as if its split payment",
      },
      split_payment_installments_count: {
        show: false,
        message: "Please select Number of Installments",
      },
      installment_payment_date:{
        show:false,
        message:"Please select installment date",
        index:[] as string[]
      },
      installment_amount:{
        show:false,
        message:"Please enter installment amount",
        index:[] as string[]
      },
      installment_cib_receipt:{
        show:false,
        message:"Please enter Internal Receipt No.",
        index:[] as string[]
      },
      installment_amount_total:{
        show:false,
        type:''
      },

    });
    onMounted(() => {
      if(!roles.amendInvoice.includes(roles.userId())) window.location.href = '/#/404'

      const nameRef:any = inst?.refs?.nameRef
      nameRef.focus()
      
      
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Amend Invoice", ["Invoice"]);
      InvoiceService.getInsurancePolicyList();

      // UserService.getDomSalesAgentsManagerTeamLeader().then((result) => {
      //   salesAgentList.value = result.data.data.agents;
      // });
      getAmendmentDetails();
      InvoiceService.clearIsEmailError();
    });
    let expiryDate = ref<string>("");
    let paymentDate = ref<string>("");
    let expiryDateTime: any;
    expiryDateTime = "";
    const getExpiryDateTime = (dateTime) => {
      const dateformart = moment(
        dateTime.target.value,
        "DD/MM/YYYY",
        true
      ).isValid()
        ? moment(dateTime.target.value, "DD/MM/YYYY").format("YYYY-MM-DD")
        : dateTime.target.value;
      var dueDateTime = new Date(dateformart);
      addInvoiceBodyParam.value.expiry_date_time =
        dueDateTime.getFullYear() +
        "-" +
        (dueDateTime.getMonth() + 1) +
        "-" +
        dueDateTime.getDate();
    };
    const getPolicyEndDate = (dateTime) => {
      const dateformart = moment(
        dateTime.target.value,
        "DD/MM/YYYY",
        true
      ).isValid()
        ? moment(dateTime.target.value, "DD/MM/YYYY").format("YYYY-MM-DD")
        : dateTime.target.value;
      var policyEndDate = new Date(dateformart);
      addInvoiceBodyParam.value.policy_end_date =
        policyEndDate.getFullYear() +
        "-" +
        (policyEndDate.getMonth() + 1) +
        "-" +
        policyEndDate.getDate();
    };

    let errMsg = ref<boolean>(false);
    const isDecimalValid = ref(true);
    const isDecimalAmountValid = ref(true);
    //validate the form
    let isFormValid = ref<boolean>(true);
    const validateAddInvoice = () => {
      let singleCount = addInvoiceBodyParam.value.new_name.match(/[']/g);
      let minusCount = addInvoiceBodyParam.value.new_name.match(/[-]/g);
      let decimalPlaces = /^[0-9]*(\.[0-9]{0,2})?$/;

      let singleCountChecker = true;
      let minusCountChecker = true;

      if (singleCount) {
        singleCountChecker = singleCount.length > 1 ? false : true;
      }
      if (minusCount) {
        minusCountChecker = minusCount.length > 1 ? false : true;
      }

      if (!singleCountChecker || !minusCountChecker) {
        rules.value.new_name.message = `Only character a-z, - and ' allowed`;
        rules.value.new_name.show = true;
        isFormValid.value = false;
      }

      if (!decimalPlaces.test(String(addInvoiceBodyParam.value.car_value))) {
        rules.value.car_value.message =
          "Please enter upto 2 decimal places only";
        rules.value.car_value.show = true;
        isDecimalValid.value = false;
      }

      if (!decimalPlaces.test(String(addInvoiceBodyParam.value.amount))) {
        rules.value.amount.message = "Please enter upto 2 decimal places only";
        rules.value.amount.show = true;
        isDecimalAmountValid.value = false;
      }
      if(addInvoiceBodyParam.value.amount  >= 1000000000) {
          isFormValid.value = false;
          // notif.simple("Please Note", "warning", 'Incorrect amount field entered.', 0);
          rules.value.amount_error.message =
            "Incorrect amount";
          rules.value.amount_error.show = true;
          isDecimalValid.value = false;
        }

      if(addInvoiceBodyParam.value.car_value  >= 1000000000 || 
        addInvoiceBodyParam.value.amount  >= 1000000000
        ) {
          isFormValid.value = false;
          // notif.simple("Please Note", "warning", 'Incorrect amount field entered.', 0);
        }

      let singleCountName = amendInvoiceInformation.value.name.match(/[']/g);
      let minusCountName = amendInvoiceInformation.value.name.match(/[-]/g);

      let singleCountNameChecker = true;
      let minusCountNameChecker = true;

      if (singleCountName) {
        singleCountNameChecker = singleCountName.length > 1 ? false : true;
      }
      if (minusCountName) {
        minusCountNameChecker = minusCountName.length > 1 ? false : true;
      }

      if (!singleCountNameChecker || !minusCountNameChecker) {
        rules.value.name.message = `Only character a-z, - and ' allowed`;
        rules.value.name.show = true;
        isFormValid.value = false;
      }

      if (
        addInvoiceBodyParam.value.amend_type == null ||
        addInvoiceBodyParam.value.amend_type == ""
      ) {
        rules.value.amend_type.show = true;
        isFormValid.value = false;
      }

    if (addInvoiceBodyParam.value.payment_type == "1") {
        if (!expiryDate.value) {
          rules.value.expiry_date.show = true;
          isFormValid.value = false;
        }
        if (
          addInvoiceBodyParam.value.language == null ||
          addInvoiceBodyParam.value.language == "-1"
        ) {
          rules.value.language.show = true;
          isFormValid.value = false;
        }
      }
      if (
        addInvoiceBodyParam.value.payment_type == "2" ||
        addInvoiceBodyParam.value.payment_type == "3"
      ) {
        if (file == undefined) {
          rules.value.file_data.show = true;
          isFormValid.value = false;
        }
        else
        {
          rules.value.file_data.show = false;
        }

        if(addInvoiceBodyParam.value.reference_no == ""){
          rules.value.reference_no.show = true;
          isFormValid.value = false;
        }
        else if(addInvoiceBodyParam.value.reference_no != "" && addInvoiceBodyParam.value.reference_no.length > 30){
          rules.value.reference_no.show = true;
          rules.value.reference_no.message = "Reference No. should be less than 30 characters";
          isFormValid.value = false;
        }
        else
        {
          rules.value.reference_no.show = false;
        }

        if(paymentDate.value == ""){
          rules.value.payment_date.show = true;
          isFormValid.value = false;
        }


      }
      // if (addInvoiceBodyParam.value.policy_sales_agent_id == "-1") {
      //   rules.value.policy_agent.show = true;
      //   isFormValid.value = false;
      // }
      if (
        amendInvoiceInformation.value.name == null ||
        amendInvoiceInformation.value.name == ""
      ) {
        rules.value.name.show = true;
        isFormValid.value = false;
      }
      if (
        amendInvoiceInformation.value.email == null ||
        amendInvoiceInformation.value.email == ""
      ) {
        rules.value.email.show = true;
        isFormValid.value = false;
      }
      else if (amendInvoiceInformation.value.email != null && amendInvoiceInformation.value.email != "" && !validation.validateEmail(amendInvoiceInformation.value.email)) {
          rules.value.email.message = 'Please enter valid email address';
          rules.value.email.show = true;
          isFormValid.value = false;
      }
      else
      {
        rules.value.email.show = false;
      }
      
      if (
        addInvoiceBodyParam.value.amount == null ||
        addInvoiceBodyParam.value.amount == 0
      ) {
        rules.value.amount.show = true;
        isFormValid.value = false;
      }

      if (
        addInvoiceBodyParam.value.order_description == null ||
        addInvoiceBodyParam.value.order_description == ""
      ) {
        rules.value.order_description.show = true;
        isFormValid.value = false;
      }
      if (
        addInvoiceBodyParam.value.payment_type == "-1" ||
        addInvoiceBodyParam.value.payment_type == ""
      ) {
        rules.value.payment_type.show = true;
        isFormValid.value = false;
      }
      if (addInvoiceBodyParam.value.amend_type == 2) {
        console.log(
          "addInvoiceBodyParam.value.policy_end_date",
          addInvoiceBodyParam.value.policy_end_date
        );
        if (
          addInvoiceBodyParam.value.policy_end_date == null ||
          addInvoiceBodyParam.value.policy_end_date == ""
        ) {
          rules.value.policy_end_date.show = true;
          isFormValid.value = false;
          console.log(
            "rules.value.policy_end_date.show",
            rules.value.policy_end_date.show
          );
        }
      }
      if (addInvoiceBodyParam.value.amend_type == 3) {
        if (
          addInvoiceBodyParam.value.new_name == null ||
          addInvoiceBodyParam.value.new_name == ""
        ) {
          rules.value.new_name.show = true;
          isFormValid.value = false;
        }

        if (
          addInvoiceBodyParam.value.new_email == null ||
          addInvoiceBodyParam.value.new_email == ""
        ) {
          rules.value.new_email.show = true;
          isFormValid.value = false;
        } else if (
          addInvoiceBodyParam.value.new_email != null &&
          addInvoiceBodyParam.value.new_email != "" &&
          !validation.validateEmail(addInvoiceBodyParam.value.new_email)
        ) {
          rules.value.new_email.message = "Please enter valid email address";
          rules.value.new_email.show = true;
          isFormValid.value = false;
        } else {
          rules.value.new_email.show = false;
        }

        if (
          addInvoiceBodyParam.value.new_phone_number == null ||
          addInvoiceBodyParam.value.new_phone_number == ""
        ) {
          rules.value.new_phone_number.message = 'Please enter new customer phone number';
          rules.value.new_phone_number.show = true;
          isFormValid.value = false;
        }
        
        const minPhoneLimit = phoneNumberValidation.setLimit(addInvoiceBodyParam.value.new_phone_country_code,'min');
        if (
          addInvoiceBodyParam.value.new_phone_number &&
          addInvoiceBodyParam.value.new_phone_number.length < minPhoneLimit
        ) {
          rules.value.new_phone_number.message = `Phone number must not be less than ${minPhoneLimit} digits`;
          rules.value.new_phone_number.show = true;
          isFormValid.value = false;
        }
        // if (
        //   addInvoiceBodyParam.value.new_phone_number != "" &&
        //   addInvoiceBodyParam.value.new_phone_number.trim().length < 8
        // ) {
        //   rules.value.new_phone_number.show = true;
        //   isFormValid.value = false;
        // }

        if (
          addInvoiceBodyParam.value.car_value == null ||
          addInvoiceBodyParam.value.car_value == 0
        ) {
          rules.value.car_value.show = true;
          isFormValid.value = false;
        }

        if (
          addInvoiceBodyParam.value.nationality == null ||
          addInvoiceBodyParam.value.nationality == 0
        ) {
          rules.value.nationality.show = true;
          isFormValid.value = false;
        }
        if (
          addInvoiceBodyParam.value.dob == null ||
          addInvoiceBodyParam.value.dob == ""
        ) {
          rules.value.dob.show = true;
          isFormValid.value = false;
        }
        if (addInvoiceBodyParam.value.dob && checkdateLeadDriverDob.value < 18) {
          rules.value.dob.message = "DOB is less than 18 years old";
          rules.value.dob.show = true;
          isFormValid.value = false;
        }
        if (
          addInvoiceBodyParam.value.first_driving_license_country == null ||
          addInvoiceBodyParam.value.first_driving_license_country == -1
        ) {
          rules.value.first_driving_license_country.show = true;
          isFormValid.value = false;
        }
        if (
          addInvoiceBodyParam.value.uae_driving_experience == null ||
          addInvoiceBodyParam.value.uae_driving_experience == -1
        ) {
          rules.value.uae_driving_experience.show = true;
          isFormValid.value = false;
        }
        if (
          addInvoiceBodyParam.value.driving_experience == null ||
          addInvoiceBodyParam.value.driving_experience == -1
        ) {
          rules.value.driving_experience.show = true;
          isFormValid.value = false;
        }

        if (
          (addInvoiceBodyParam.value.no_claim_certificate == null ||
            addInvoiceBodyParam.value.no_claim_certificate == "") &&
          addInvoiceBodyParam.value.claimed_insurance == 2
        ) {
          rules.value.no_claim_certificate.show = true;
          isFormValid.value = false;
        }

        if (
          addInvoiceBodyParam.value.claimed_insurance == 1 &&
          (!addInvoiceBodyParam.value.claims ||
            addInvoiceBodyParam.value.claims < 0)
        ) {
          rules.value.claims.show = true;
          isFormValid.value = false;
        }

        if (
          addInvoiceBodyParam.value.driver_name == "" ||
          addInvoiceBodyParam.value.driver_name == null
        ) {
          rules.value.driver_name.show = true;
          isFormValid.value = false;
        }
        if (
          addInvoiceBodyParam.value.existing_policy_expired == null ||
          addInvoiceBodyParam.value.existing_policy_expired == ""
        ) {
          rules.value.existing_policy_expired.show = true;
          isFormValid.value = false;
        }
        if (
          addInvoiceBodyParam.value.personal_use == null ||
          addInvoiceBodyParam.value.personal_use == ""
        ) {
          rules.value.personal_use.show = true;
          isFormValid.value = false;
        }
        if (
          addInvoiceBodyParam.value.third_party_liability == null ||
          addInvoiceBodyParam.value.third_party_liability == ""
        ) {
          rules.value.third_party_liability.show = true;
          isFormValid.value = false;
        }
        if (
          addInvoiceBodyParam.value.policy_start_date == null ||
          addInvoiceBodyParam.value.policy_start_date == ""
        ) {
          rules.value.policy_start_date.show = true;
          isFormValid.value = false;
        }

        if (addInvoiceBodyParam.value.gender == null) {
          rules.value.gender.show = true;
          isFormValid.value = false;
        }
      }
      if (!addInvoiceBodyParam.value.insurance_payment) {
        rules.value.insurance_payment.show = true;
        isFormValid.value = false;
      }
            /**
       * Installments Validations
       */
       let invoiceBodyParams = addInvoiceBodyParam.value;
      if(invoiceBodyParams.is_split_payment == "1")
      {
        if (invoiceBodyParams.split_payment_installments_count == "") {
          rules.value.split_payment_installments_count.show = true;
          isFormValid.value = false;
        }

        if (invoiceBodyParams.installments) {
          // Initialize arrays separately
          let amountIndex: string[] = [];
          let paymentDateIndex: string[] = [];
          let cibReceiptIndex: string[] = [];
          let totalInstallmentAmount= 0;
          
          // Use forEach instead of map since we are not returning a new array
          invoiceBodyParams.installments.forEach(function(ins) {
            let key = (Number(ins.installment_no) - 1).toString();;
            let installmentAmount = parseFloat(ins.installment_amount);
            if (installmentAmount <= 0) {
              amountIndex.push(key);
            }
            if (ins.installment_payment_date === '') {
              paymentDateIndex.push(key);
            }
            if (key == '0' && ins.installment_cib_receipt === '') {
              cibReceiptIndex.push(key);
            }

            totalInstallmentAmount += installmentAmount;
          });

          if(amountIndex.length > 0){
            rules.value.installment_amount.show = true;
            rules.value.installment_amount.index = amountIndex;
            isFormValid.value = false;
          }
          if(paymentDateIndex.length > 0){
            rules.value.installment_payment_date.show = true;
            rules.value.installment_payment_date.index = paymentDateIndex;
            isFormValid.value = false;
          }
          if(cibReceiptIndex.length > 0){
            rules.value.installment_cib_receipt.show = true;
            rules.value.installment_cib_receipt.index = cibReceiptIndex;
            isFormValid.value = false;
          }
          
          if(totalInstallmentAmount != addInvoiceBodyParam.value.amount){
            rules.value.installment_amount_total.show = true;
            rules.value.installment_amount_total.type = (totalInstallmentAmount > addInvoiceBodyParam.value.amount) ? 'higher' : 'lower';
            isFormValid.value = false;
          }else{
            rules.value.installment_amount_total.show = false;
            rules.value.installment_amount_total.type = '';
          }
        }
      }
    };

    const resetValidation = () => {
      rules.value.amend_type.show = false;
      rules.value.policy_sales_agent_id.show = false;
      rules.value.name.show = false;
      rules.value.email.show = false;
      rules.value.amount.show = false;
      rules.value.language.show = false;
      rules.value.order_description.show = false;
      rules.value.payment_type.show = false;
      rules.value.expiry_date.show = false;
      rules.value.policy_agent.show = false;
      rules.value.policy_end_date.show = false;
      rules.value.new_name.show = false;
      rules.value.new_email.show = false;
      rules.value.new_phone_number.show = false;
      rules.value.nationality.show = false;
      rules.value.dob.show = false;
      rules.value.first_registration_date.show = false;
      rules.value.first_driving_license_country.show = false;
      rules.value.uae_driving_experience.show = false;
      rules.value.driving_experience.show = false;
      rules.value.driver_name.show = false;
      rules.value.file_data.show = false;
      rules.value.existing_policy_expired.show = false;
      rules.value.personal_use.show = false;
      rules.value.third_party_liability.show = false;
      rules.value.policy_start_date.show = false;
      rules.value.claimed_insurance.show = false;
      rules.value.no_claim_certificate.show = false;
      rules.value.claims.show = false;
      rules.value.gender.show = false;
    };

    const invoiceModalRef = ref();

    const loading = ref<boolean>(false);
    const addInvoice = async() => {
      resetValidation();
      isDecimalValid.value = true;
      isDecimalAmountValid.value = true;
      isFormValid.value = true;
      validateAddInvoice();

      
      
        let formData = new FormData();
        if (expiryDateTime == "") {
          var dueDateTime = new Date();
          expiryDateTime =
            dueDateTime.getFullYear() +
            "-" +
            (dueDateTime.getMonth() + 1) +
            "-" +
            dueDateTime.getDate() +
            " " +
            dueDateTime.toLocaleTimeString();
        }
        addInvoiceBodyParam.value.expiry_date_time = expiryDateTime.value;
        formData.append("name", amendInvoiceInformation.value.name);
        formData.append("email", amendInvoiceInformation.value.email);
        formData.append(
          "phone_number",
          amendInvoiceInformation.value.phone_number
        );

        formData.append(
          "merchant_reference",
          amendInvoiceInformation.value.merchant_reference
        );
        formData.append(
          "invoice_number",
          amendInvoiceInformation.value.parent_merchant_reference
        );
        formData.append("invoice_id", amendInvoiceInformation.value.invoice_id);

        formData.append(
          "policy_sales_agent_id",
          amendInvoiceInformation.value.policy_sales_agent_id
        );
        formData.append(
          "original_price",
          addInvoiceBodyParam.value.original_price
        );
        formData.append("amount", addInvoiceBodyParam.value.amount.toString());
        formData.append("payment_type", addInvoiceBodyParam.value.payment_type);
        formData.append(
          "order_description",
          addInvoiceBodyParam.value.order_description
        );
        
        formData.append("reference_no", addInvoiceBodyParam.value.reference_no);
        //For COD or Direct
        if(addInvoiceBodyParam.value.payment_type == '2' || addInvoiceBodyParam.value.payment_type == '3' || addInvoiceBodyParam.value.payment_type == '5' || addInvoiceBodyParam.value.payment_type == '6')
        {
          formData.append("payment_date", paymentDate.value);
        }
        formData.append("language", addInvoiceBodyParam.value.language);
        formData.append("expiry_date_time",expiryDate.value);
        formData.append("is_amend", addInvoiceBodyParam.value.is_amend);
        formData.append("amend_type", addInvoiceBodyParam.value.amend_type);
        formData.append("insurance_payment", addInvoiceBodyParam.value.insurance_payment);

        formData.append("invoice_doc", file);
        // For extension
        formData.append(
          "policy_end_date",
          addInvoiceBodyParam.value.policy_end_date
        );
        //For name transfer
        if (addInvoiceBodyParam.value.amend_type == 3) {
          formData.append("new_name", addInvoiceBodyParam.value.new_name);
          formData.append("new_email", addInvoiceBodyParam.value.new_email);
          formData.append(
            "new_phone_country_code",
            addInvoiceBodyParam.value.new_phone_country_code
          );
          formData.append(
            "new_phone_number",
            addInvoiceBodyParam.value.new_phone_number
          );
          formData.append("car_value", addInvoiceBodyParam.value.car_value.toString());
          formData.append("nationality", addInvoiceBodyParam.value.nationality);
          formData.append("dob", addInvoiceBodyParam.value.dob);
          formData.append(
            "first_driving_license_country",
            addInvoiceBodyParam.value.first_driving_license_country
          );
          formData.append(
            "driving_experience",
            addInvoiceBodyParam.value.driving_experience
          );
          formData.append(
            "uae_driving_experience",
            addInvoiceBodyParam.value.uae_driving_experience
          );
          formData.append(
            "no_claim_certificate",
            addInvoiceBodyParam.value.no_claim_certificate
          );
          formData.append("claims", addInvoiceBodyParam.value.claims);

          formData.append(
            "claimed_insurance",
            addInvoiceBodyParam.value.claimed_insurance
          );
          formData.append(
            "personal_use",
            addInvoiceBodyParam.value.personal_use
          );
          formData.append(
            "third_party_liability",
            addInvoiceBodyParam.value.third_party_liability
          );
          formData.append(
            "existing_policy_expired",
            addInvoiceBodyParam.value.existing_policy_expired
          );
          formData.append(
            "current_policy_active",
            addInvoiceBodyParam.value.current_policy_active
          );
          formData.append(
            "fully_comprehensive",
            addInvoiceBodyParam.value.fully_comprehensive
          );
          formData.append("driver_name", addInvoiceBodyParam.value.driver_name);
          formData.append(
            "policy_start_date",
            addInvoiceBodyParam.value.policy_start_date
          );

          formData.append("gender", addInvoiceBodyParam.value.gender);
        }

      // formData.append("is_split_payment", addInvoiceBodyParam.value.is_split_payment);
      // formData.append("installment_count", addInvoiceBodyParam.value.split_payment_installments_count);
      // if(addInvoiceBodyParam.value.is_split_payment !== '' && addInvoiceBodyParam.value.installments.length > 0){
      //   formData.append("installments", encodeURIComponent(JSON.stringify(addInvoiceBodyParam.value.installments)));
      // }

        
      if (isFormValid.value) {
        loading.value = true;

        const data = await InvoiceService.addAmendInvoice(formData)

        if(data.status < 299) {
          notif.simple("Amend Invoice", "success", "You have successfully created amend invoice");
            const response = data.data.data.invoice
            if(response.payment_link) {
              store.commit(Mutations.SET_INVOICE_MODAL)
              store.commit(Mutations.SET_INVOICE_MODAL_VALUE, response)
            } else {
              router.push({ name: "invoice-list" });
            }
            setTimeout(() => {
              loading.value = false;
            }, 3000);
        } else {
            if(data.data.data.reference_no) {
              rules.value.reference_no.show = true;
              rules.value.reference_no.message = 'Reference no. already exists'
            }
            loading.value = false;
        }

        // loading.value = true;
        // InvoiceService.addAmendInvoice(formData)
        //   .then((data) => {
            

        //     //   Swal.fire({
        //     //   text: "Customer successfully added!",
        //     //   icon: "success",
        //     //   showCancelButton: false,
        //     //   showConfirmButton: false,
        //     //   timer: 3000
        //     // })
        //     notif.simple("Amend Invoice", "success", "You have successfully created amend invoice");
        //     const response = data.data.invoice
        //     if(response.payment_link) {
        //       store.commit(Mutations.SET_INVOICE_MODAL)
        //       store.commit(Mutations.SET_INVOICE_MODAL_VALUE, response)
        //     } else {
        //       router.push({ name: "invoice-list" });
        //     }
        //     setTimeout(() => {
        //       loading.value = false;
        //     }, 3000);
        //   })
        //   .catch((err) => {
        //     console.log({err});
        //     // notif.simple('Customer', 'warning', 'error!')
        //     loading.value = false;
        //   });
      } else {
        focusField()
        loading.value = false;
      }
    };
    // let salesAgentList = ref({});

    // let agentList = ref({
    //   data: [],
    //   options: salesAgentList,
    //   placeholder: "Please Select Policy Sales Agent",
    //   searchable: true,
    //   createTag: true,
    // });
    const onSalesAgentChange = (event) => {
      addInvoiceBodyParam.value.policy_sales_agent_id = event;
    };
    const resetSalesAgent = () => {
      addInvoiceBodyParam.value.policy_sales_agent_id = null;
    };

    const getVehicleUse = (event) => {
      addInvoiceBodyParam.value.personal_use = event.target.value;
    };

    const getThirdParty = (event) => {
      addInvoiceBodyParam.value.third_party_liability = event.target.value;
      addInvoiceBodyParam.value.fully_comprehensive =
        event.target.value == "1" ? 2 : 1;
    };

    const getPolicyExpiry = (event) => {
      addInvoiceBodyParam.value.existing_policy_expired = event.target.value;
      addInvoiceBodyParam.value.current_policy_active =
        addInvoiceBodyParam.value.existing_policy_expired == 2 ? 1 : 2;
    };
    const getNationality = (event) => {
      addInvoiceBodyParam.value.nationality = event;
    };

    const getDlNationality = (event) => {
      addInvoiceBodyParam.value.first_driving_license_country = event;
    };

    const getDOB = () => {
      // const dateformart = moment(dob.target.value, 'DD/MM/YYYY', true).isValid()
      // ? moment(dob.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD')
      // : dob.target.value;
      // var dobTS = new Date(dateformart);
      // addInvoiceBodyParam.value.dob = dob.value
    };

    const getHomeDrivingExperience = (event) => {
      addInvoiceBodyParam.value.driving_experience = event;
    };
    const getUaeDrivingExperience = (event) => {
      addInvoiceBodyParam.value.uae_driving_experience = event;
    };
    const getNoClaim = (event) => {
      addInvoiceBodyParam.value.no_claim_certificate = event;
    };
    const showInvoiceDom = computed(() => {
      return store.getters.getInvoiceDomStatus;
    });
    let file: any;
    const handleFileUpload = (event) => {
      rules.value.file_data.show = false
      rules.value.file_data.message = "Please upload file for COD/OMT Payment/Cash deposit"
      isFormValid.value = true

      // formData = new FormData();
      file = event.target.files[0];
      if (file == "" && addInvoiceBodyParam.value.payment_type != "1") {
        rules.value.file_data.show = false;
        isFormValid.value = false;
      }

      if((event.target.files[0].size / 1024 / 1024) > 2) {
        rules.value.file_data.show = true
        rules.value.file_data.message = "Document size exceed limit of 2mb"
        isFormValid.value = false
      }
      //
    };
    const setPaymentMode = () => {
      rules.value.payment_type.show = false;
      rules.value.insurance_payment.show = false;
      if (addInvoiceBodyParam.value.payment_type == "3") {
        addInvoiceBodyParam.value.order_description =
          amendInvoiceInformation.value.policy_name;

        addInvoiceBodyParam.value.insurance_payment = 2;
      }
      else if(addInvoiceBodyParam.value.payment_type == "1") {
        addInvoiceBodyParam.value.order_description = "";
        addInvoiceBodyParam.value.insurance_payment = 1;
      }
      else {
        addInvoiceBodyParam.value.insurance_payment = '';
        addInvoiceBodyParam.value.order_description = "";
      }
      isFormValid.value = false;

      rules.value.file_data.show = false;
      rules.value.expiry_date.show = false;
    };

    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });

    let countryList = computed(() => {
      return store.getters.getCountry;
    });
    const nationality = ref({
      placeholder: "Please Select Nationality",
      value: null,
      options: countryList,
      searchable: true,
      clearOnSelect: true
    });

    let dlCountryList = computed(() => {
      return store.getters.getDlCountry;
    });

    const dlCountry = ref({
      placeholder: "Please Select Country",
      value: null,
      options: dlCountryList,
      searchable: true,
      clearOnSelect: true
    });
    const uaeExperience = ref({
      placeholder: "Please Select Experience",
      value: null,
      options: [
        { value: "1", label: "0-6 months" },
        { value: "2", label: "6 - 12 months" },
        { value: "3", label: "1 - 2 Years" },
        { value: "4", label: "More than 2 Years" }
      ],
      searchable: true,
      clearOnSelect: true
    });
    const homeExperience = ref({
      placeholder: "Please Select Experience",
      value: null,
      options: [
        { value: "1", label: "0-6 months" },
        { value: "2", label: "6 - 12 months" },
        { value: "3", label: "1 - 2 Years" },
        { value: "4", label: "More than 2 Years" }
      ],
      searchable: true,
      clearOnSelect: true
    });

    const ncc = ref({
      placeholder: "Please Select",
      value: "1",
      options: [
        { value: "1", label: "No - I have no certificate" },
        { value: "2", label: "Yes - 1 year with proof" },
        { value: "3", label: "Yes - 2 years with proof" },
        { value: "4", label: "Yes - 3 or more years with proof" }
      ],
      searchable: true,
      clearOnSelect: true
    });
    const getAmendmentDetails = () => {
      Promise.all([
        InvoiceService.getAmendInvoiceInformation({
          invoice_id: route.params.invoiceId,
          amend_type: addInvoiceBodyParam.value.amend_type
        })
      ]).then((data) => {
        console.log(data);
        if (addInvoiceBodyParam.value.amend_type == 3) {
          MasterService.getCountryCodes();
          QuotesService.getCountries();
        }

        amendInvoiceInformation.value = data[0];
      });
    };

    let countryCodeList = computed(() => {
      return store.getters.getCountryCode;
    });

    let countryCodes = ref({
      data: [],
      value: "+961",
      options: countryCodeList,
      searchable: true,
      createTag: false
    });
    const onCountryCodeChange = (event) => {
      addInvoiceBodyParam.value.new_phone_country_code = event;
    };

    const yearMinuseighteen = computed(() => {
      const datenow = new Date();
      const day = datenow.getDate();
      const month = datenow.getUTCMonth();
      const year = datenow.getFullYear() - 18;
      return new Date(year, month, day);
    });

    const yearMinusOne = computed(() => {
      const datenow = new Date();
      const day = datenow.getDate();
      const month = datenow.getUTCMonth();
      const year = datenow.getFullYear();
      return new Date(year, month, day);
    });

    const disabledDate = (time: any) => {
      return time.getTime() > new Date(yearMinuseighteen.value);
    };

    const disableDateOne = (time: any) => {
      return time.getTime() < new Date(yearMinusOne.value);
    };

    const resetClaims = () => {
      if (addInvoiceBodyParam.value.claimed_insurance == 1) {
        addInvoiceBodyParam.value.no_claim_certificate = "";
        addInvoiceBodyParam.value.claims = "1";
        ncc.value.value = "";
      } else {
        addInvoiceBodyParam.value.no_claim_certificate = 1;
        addInvoiceBodyParam.value.claims = "0";
        ncc.value.value = "1";
      }
    };

    const clearHomeDrivingExperience = () => {
      addInvoiceBodyParam.value.driving_experience = null;
    };

    const clearUaeDrivingExperience = () => {
      addInvoiceBodyParam.value.uae_driving_experience = null;
    };

    const checkdateLeadDriverDob = computed(() => {
      return dateDif(addInvoiceBodyParam.value.dob);
    });

    const dateDif = (date: any) => {
      if (date) {
        date = date.split("-");
        var today = new Date();
        var year = today.getFullYear();
        var month = today.getMonth() + 1;
        var day = today.getDate();
        var yy = parseInt(date[0]);
        var mm = parseInt(date[1]);
        var dd = parseInt(date[2]);
        var years, months, days;
        // months
        months = month - mm;
        if (day < dd) {
          months = months - 1;
        }
        // years
        years = year - yy;
        if (month * 100 + day < mm * 100 + dd) {
          years = years - 1;
          months = months + 12;
        }
        // days
        days = Math.floor(
          (today.getTime() -
            new Date(yy + years, mm + months - 1, dd).getTime()) /
            (24 * 60 * 60 * 1000)
        );
        //
        return years;
      }
      return 0;
    };


    const addDayDate = computed(() => {
      const today = new Date()
      const tomorrow = new Date(today)
      return moment(tomorrow.setDate(tomorrow.getDate() + 1)).format("YYYY-MM-DD")
    });

    const currentyear = computed(() => {
      return new Date().getFullYear();
    })

    const dobYear = computed(() => {
      return currentyear.value - 18;
    })

    const currentDay = computed(() => {
      const today = new Date().getDate();
      return today > 9 ? today : '0'+today;
    })

    const currentMonth = computed(() => {
      const month = new Date().getMonth() + 1;
      return month > 9 ? month : '0'+month;
    })

    const day = computed(() => {
      const today = new Date().getDate();
      return today > 9 ? today : '0'+today;
    })

    const passValueExpiryDate = () => {
      addInvoiceBodyParam.value.expiry_date_time = expiryDate.value
      const languageRef:any = inst?.refs?.languageRef
      languageRef.focus()
    }

    const toAmount = () => {
      const amountRef:any = inst?.refs?.amountRef
        amountRef.isFocus()
    }

    const toExpectedPolicy = () => {
      const expectedPolicyStartRef:any = inst?.refs?.expectedPolicyStartRef
        expectedPolicyStartRef.isFocus()
    }

    const toDriverNationality = () => {
        const driverNationalityRef:any = inst?.refs?.driverNationalityRef
        driverNationalityRef.focusInput()
    }

    const inst = getCurrentInstance()

    const focusField = () => {
      if(['1', '4', '5'].includes(addInvoiceBodyParam.value.amend_type)) {
        premiumType()
      }
      if(addInvoiceBodyParam.value.amend_type === '2') {
        extensionType()
      }
      if(addInvoiceBodyParam.value.amend_type === '3') {
        nameTransferType()
      }

    }

    const premiumType = () => {
      const { name, email, amount, payment_type, order_description, reference_no, expiry_date } = rules.value

      if(name.show) {
        const nameRef:any = inst?.refs?.nameRef
        nameRef.focus()
      }

      if(!name.show && email.show) {
        const emailRef:any = inst?.refs?.emailRef
        emailRef.isFocus()
      }

      if(!name.show && !email.show && amount.show) {
        const amountRef:any = inst?.refs?.amountRef
        amountRef.isFocus()
      }

      if(!name.show && !email.show && !amount.show && payment_type.show) {
        const paymentTypeRef:any = inst?.refs?.paymentTypeRef
        paymentTypeRef.focus()
      }

      if(!name.show && !email.show && !amount.show && !payment_type.show && order_description.show) {
        const orderDescriptionRef:any = inst?.refs?.orderDescriptionRef
        orderDescriptionRef.focus()
      }

      if(['2', '3'].includes(addInvoiceBodyParam.value.payment_type)) {
        if(!name.show && !email.show && !amount.show && !payment_type.show && !order_description.show && reference_no.show) {
          const referenceNoRef:any = inst?.refs?.referenceNoRef
          referenceNoRef.focus()
        }
      }

      if(addInvoiceBodyParam.value.payment_type === '1') {
        if(!name.show && !email.show && !amount.show && !payment_type.show && !order_description.show && expiry_date.show) {
          const expiryDateRef:any = inst?.refs?.expiryDateRef
          expiryDateRef.isFocus()
        }
      }
    }

    const extensionType = () => {
      const { name, email, policy_end_date, amount, payment_type, order_description, reference_no, expiry_date } = rules.value

      if(name.show) {
        const nameRef:any = inst?.refs?.nameRef
        nameRef.focus()
      }

      if(!name.show && email.show) {
        const emailRef:any = inst?.refs?.emailRef
        emailRef.focus()
      }
      
      if(!name.show && !email.show && policy_end_date.show) {
        const policyEndRef:any = inst?.refs?.policyEndRef
        policyEndRef.isFocus()
      }

      if(!name.show && !email.show && !policy_end_date.show && amount.show) {
        const amountRef:any = inst?.refs?.amountRef
        amountRef.isFocus()
      }

      if(!name.show && !email.show && !policy_end_date.show && !amount.show && payment_type.show) {
        const paymentTypeRef:any = inst?.refs?.paymentTypeRef
        paymentTypeRef.focus()
      }

      if(!name.show && !email.show && !policy_end_date.show && !amount.show && !payment_type.show && order_description.show) {
        const orderDescriptionRef:any = inst?.refs?.orderDescriptionRef
        orderDescriptionRef.focus()
      }

      if(['2', '3'].includes(addInvoiceBodyParam.value.payment_type)) {
        if(!name.show && !email.show && !policy_end_date.show && !amount.show && !payment_type.show && !order_description.show && reference_no.show) {
          const referenceNoRef:any = inst?.refs?.referenceNoRef
          referenceNoRef.focus()
        }
      }

      if(addInvoiceBodyParam.value.payment_type === '1') {
        if(!name.show && !email.show && !policy_end_date.show && !amount.show && !payment_type.show && !order_description.show && expiry_date.show) {
          const expiryDateRef:any = inst?.refs?.expiryDateRef
          expiryDateRef.isFocus()
        }
      }
    }

    const nameTransferType = () => {
      const { name, email, new_name, new_email, new_phone_number, car_value, dob, policy_start_date, nationality, first_driving_license_country, driving_experience, uae_driving_experience, no_claim_certificate, claims, gender, driver_name, amount, payment_type, order_description, reference_no, expiry_date } = rules.value

      if(name.show) {
        const nameRef:any = inst?.refs?.nameRef
        nameRef.focus()
      }

      if(!name.show && email.show) {
        const emailRef:any = inst?.refs?.emailRef
        emailRef.focus()
      }

      if(!name.show && !email.show && new_name.show) {
        const customerNameRef:any = inst?.refs?.customerNameRef
        customerNameRef.focus()
      }

      if(
        !name.show && 
        !email.show && 
        !new_name.show &&
        new_email.show
        ) {
        const newEmailRef:any = inst?.refs?.newEmailRef
        newEmailRef.focus()
      }

      if(
        !name.show && 
        !email.show && 
        !new_name.show &&
        !new_email.show && 
        new_phone_number.show
        ) {
        const newPhoneRef:any = inst?.refs?.newPhoneRef
        newPhoneRef.focus()
      }

      if(
        !name.show && 
        !email.show && 
        !new_name.show &&
        !new_email.show && 
        !new_phone_number.show && 
        car_value.show
        ) {
        const carValueRef:any = inst?.refs?.carValueRef
        carValueRef.isFocus()
      }

      if(
        !name.show && 
        !email.show && 
        !new_name.show &&
        !new_email.show && 
        !new_phone_number.show &&
        !car_value.show &&
        dob.show
        ) {
        const dobRef:any = inst?.refs?.dobRef
        dobRef.isFocus()
      }

      if(
        !name.show && 
        !email.show && 
        !new_name.show &&
        !new_email.show && 
        !new_phone_number.show &&
        !car_value.show &&
        !dob.show && 
        policy_start_date.show
        ) {
        const expectedPolicyStartRef:any = inst?.refs?.expectedPolicyStartRef
        expectedPolicyStartRef.isFocus()
      }

      if(
        !name.show && 
        !email.show && 
        !new_name.show &&
        !new_email.show && 
        !new_phone_number.show &&
        !car_value.show &&
        !dob.show && 
        !policy_start_date.show &&
        nationality.show
        ) {
        const driverNationalityRef:any = inst?.refs?.driverNationalityRef
        driverNationalityRef.focusInput()
        window.scrollTo(0,500)
      }

      if(
        !name.show && 
        !email.show && 
        !new_name.show &&
        !new_email.show && 
        !new_phone_number.show &&
        !car_value.show &&
        !dob.show && 
        !policy_start_date.show &&
        !nationality.show && 
        first_driving_license_country.show
        ) {
          const fdlRef:any = inst?.refs?.fdlRef
        fdlRef.focusInput()
          window.scrollTo(0,500)
      }

      if(
        !name.show && 
        !email.show && 
        !new_name.show &&
        !new_email.show && 
        !new_phone_number.show &&
        !car_value.show &&
        !dob.show && 
        !policy_start_date.show &&
        !nationality.show && 
        !first_driving_license_country.show &&
        driving_experience.show
        ) {
          const ideRef:any = inst?.refs?.ideRef
        ideRef.focusInput()
          window.scrollTo(0,500)
      }

      if(
        !name.show && 
        !email.show && 
        !new_name.show &&
        !new_email.show && 
        !new_phone_number.show &&
        !car_value.show &&
        !dob.show && 
        !policy_start_date.show &&
        !nationality.show && 
        !first_driving_license_country.show &&
        !driving_experience.show &&
        uae_driving_experience.show
        ) {
          const uaeRef:any = inst?.refs?.uaeRef
        uaeRef.focusInput()
          window.scrollTo(0,500)
      }

      if(
        !name.show && 
        !email.show && 
        !new_name.show &&
        !new_email.show && 
        !new_phone_number.show &&
        !car_value.show &&
        !dob.show && 
        !policy_start_date.show &&
        !nationality.show && 
        !first_driving_license_country.show &&
        !driving_experience.show &&
        !uae_driving_experience.show && 
        claims.show
        ) {
          const noClaimRef:any = inst?.refs?.noClaimRef
        noClaimRef.focus()
          window.scrollTo(0,650)
      }

      if(
        !name.show && 
        !email.show && 
        !new_name.show &&
        !new_email.show && 
        !new_phone_number.show &&
        !car_value.show &&
        !dob.show && 
        !policy_start_date.show &&
        !nationality.show && 
        !first_driving_license_country.show &&
        !driving_experience.show &&
        !uae_driving_experience.show && 
        !claims.show && 
        no_claim_certificate.show
        ) {
          const claimRef:any = inst?.refs?.claimRef
        claimRef.focusInput()
          window.scrollTo(0,650)
      }

      if(
        !name.show && 
        !email.show && 
        !new_name.show &&
        !new_email.show && 
        !new_phone_number.show &&
        !car_value.show &&
        !dob.show && 
        !policy_start_date.show &&
        !nationality.show && 
        !first_driving_license_country.show &&
        !driving_experience.show &&
        !uae_driving_experience.show && 
        !claims.show && 
        !no_claim_certificate.show &&
        gender.show
        ) {
          const genderRef:any = inst?.refs?.genderRef
        genderRef.focus()
          window.scrollTo(0,800)
      }

      if(
        !name.show && 
        !email.show && 
        !new_name.show &&
        !new_email.show && 
        !new_phone_number.show &&
        !car_value.show &&
        !dob.show && 
        !policy_start_date.show &&
        !nationality.show && 
        !first_driving_license_country.show &&
        !driving_experience.show &&
        !uae_driving_experience.show && 
        !claims.show && 
        !no_claim_certificate.show &&
        !gender.show &&
        driver_name.show
        ) {
          const driverNameRef:any = inst?.refs?.driverNameRef
        driverNameRef.focus()
          window.scrollTo(0,800)
      }

      if(
        !name.show && 
        !email.show && 
        !new_name.show &&
        !new_email.show && 
        !new_phone_number.show &&
        !car_value.show &&
        !dob.show && 
        !policy_start_date.show &&
        !nationality.show && 
        !first_driving_license_country.show &&
        !driving_experience.show &&
        !uae_driving_experience.show && 
        !claims.show && 
        !no_claim_certificate.show &&
        !gender.show &&
        driver_name.show
        ) {
          const driverNameRef:any = inst?.refs?.driverNameRef
        driverNameRef.focus()
      }

      // 
      if(
        !name.show && 
        !email.show && 
        !new_name.show &&
        !new_email.show && 
        !new_phone_number.show &&
        !car_value.show &&
        !dob.show && 
        !policy_start_date.show &&
        !nationality.show && 
        !first_driving_license_country.show &&
        !driving_experience.show &&
        !uae_driving_experience.show && 
        !claims.show && 
        !no_claim_certificate.show &&
        !gender.show &&
        !driver_name.show
        ) {
          if(amount.show) {
            const amountRef:any = inst?.refs?.amountRef
            amountRef.isFocus()
          }

          if(!amount.show && payment_type.show) {
            const paymentTypeRef:any = inst?.refs?.paymentTypeRef
            paymentTypeRef.focus()
          }

          if(!amount.show && !payment_type.show && order_description.show) {
            const orderDescriptionRef:any = inst?.refs?.orderDescriptionRef
            orderDescriptionRef.focus()
          }

          if(['2', '3'].includes(addInvoiceBodyParam.value.payment_type)) {
            if(!amount.show && !payment_type.show && !order_description.show && reference_no.show) {
              const referenceNoRef:any = inst?.refs?.referenceNoRef
              referenceNoRef.focus()
            }
          }

          if(addInvoiceBodyParam.value.payment_type === '1') {
            if(!amount.show && !payment_type.show && !order_description.show && expiry_date.show) {
              const expiryDateRef:any = inst?.refs?.expiryDateRef
              expiryDateRef.isFocus()
            }
          }
        }
    }

    const toLanguage = () => {
        const languageRef:any = inst?.refs.languageRef
        languageRef.focus()
    }

    const toOrderDescription = () => {
        const orderDescriptionRef:any = inst?.refs.orderDescriptionRef
        orderDescriptionRef.focus()
    }

    const toExpiryDate = () => {
      const expiryDateRef:any = inst?.refs.expiryDateRef
      expiryDateRef.isFocus()
    }

    const toCancel = () => {
      const saveRef:any = inst?.refs.saveRef
        saveRef.focus()
    }

    const toEmail = () => {
      const emailRef:any = inst?.refs.emailRef
        emailRef.focus()
    }

    const toPolicyEndDate = () => {
      const policyEndRef:any = inst?.refs.policyEndRef
        policyEndRef.isFocus()
    }

    const toPaymentType = () => {
      const paymentTypeRef:any = inst?.refs.paymentTypeRef
        paymentTypeRef.focus()
    }

    const toCarValue = () => {
      const carValueRef:any = inst?.refs.carValueRef
        carValueRef.isFocus()
    }

    const toDB = () => {
      const dobRef:any = inst?.refs.dobRef
        dobRef.isFocus()
    }

    const toFirstDl = () => {
      const fdlRef:any = inst?.refs.fdlRef
        fdlRef.focusInput()
    }

    const toPhoneNumber = () => {
      const newPhoneRef:any = inst?.refs?.newPhoneRef
        newPhoneRef.focus()
    }

    const toNewEmail = () => {
      const newEmailRef:any = inst?.refs?.newEmailRef
        newEmailRef.focus()
    }

    const isErrorEmail = computed(() => {
      return store.getters.getEmailExistError
    })

    watchEffect(() => {
      if(isErrorEmail.value)
      {
        const newEmailRef:any = inst?.refs?.newEmailRef
        newEmailRef.focus()
      }

    }) 

    const insurancePayment = ref(
     [
      {
        value: 1, 
        label: "Own Account", 
        isDisabled: false
      },
      {
        value: 2, 
        label: "Payment Link Customer", 
        isDisabled: false
      },
      {
        value: 3, 
        label: "Payment Link BAI", 
        isDisabled: false
      }
    ]
    )

    const fileError = computed(() => {
      return store.getters.getAmendError
    })

    const disabledByDefault = ref(true);
    
    const isExistCustomer = computed(() => {
      return store.getters.getExistCustomer;
    })

    const checkPhoneNo = () => {
      if(addInvoiceBodyParam.value.new_phone_number) {
        CustomerService.checkExistNumber({
          phoneNo: addInvoiceBodyParam.value.new_phone_number,
          country: addInvoiceBodyParam.value.new_phone_country_code
        }).then(() => {
          disabledByDefault.value = true
          addInvoiceBodyParam.value.new_name = isExistCustomer.value.name;
          addInvoiceBodyParam.value.new_email = isExistCustomer.value.email;
          addInvoiceBodyParam.value.driver_name = isExistCustomer.value.name;
        }).catch((err) => {
          disabledByDefault.value = false
          addInvoiceBodyParam.value.new_name = null;
          addInvoiceBodyParam.value.new_email = null;
          addInvoiceBodyParam.value.driver_name = null;
          setTimeout(() => {
            const customerNameRef:any = inst?.refs?.customerNameRef
            customerNameRef.focus()
          }, 1000);
        });
      }
    }
    const closeModal = (e) => {
      
      store.commit(Mutations.SET_INVOICE_MODAL);
    };

    const changeIsSplitPayment = (event) => {
      rules.value.is_split_payment.show = false;
      addInvoiceBodyParam.value.is_split_payment = event.target.value;
      addInvoiceBodyParam.value.split_payment_installments_count = "";
    }

    const onChangeSplitPaymentInstallments = () => {
      rules.value.split_payment_installments_count.show = false;
      let installmentCount = addInvoiceBodyParam.value.split_payment_installments_count;
      if (installmentCount !== '' && parseInt(installmentCount) > 0) {
        addInvoiceBodyParam.value.installments = Array.from({ length: Number(installmentCount) }, (_, i) => ({
          ...splitPaymentInstallmentsParam.value,
          installment_no: i + 1
        }));
      }
    };


    const installmentCountText = (index) => {
      let suffix = '';
      switch (index) {
        case 1:
          suffix = "st";
          break;
        case 2:
          suffix = "nd";
          break;
        case 3:
          suffix = "rd";
          break;
        case 4:
          suffix = "th";
          break;
        default:
          suffix = "";
      }
      return `${index}<sup>${suffix}</sup> Installment`;
    }

    const isSplitPaymentDatePickerDisabled = (index) => {
        let mainPaymentDate = paymentDate.value;
        let installments    = addInvoiceBodyParam.value.installments;
        if(!mainPaymentDate || mainPaymentDate.length <= 0){
          return true;
        }else{
          if(index > 0){
            let prevDate = installments[index-1].installment_payment_date;
            if(!prevDate || prevDate.length <= 0){
              return true;
            }
          }
        }
        return false;
    };

    const splitPaymentDatePickerMin = (index) => {
          if (paymentDate.value && paymentDate.value.length > 0) {
            if (index > 0) {
              let installments = addInvoiceBodyParam.value.installments;
              let prevDate = installments[index - 1] ? installments[index - 1].installment_payment_date : null;

              if (prevDate) {
                let prevDateObj = new Date(prevDate);
                if (!isNaN(prevDateObj.getTime())) {
                  return new Date(prevDateObj.getTime() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
                }
              }
              return new Date(new Date(paymentDate.value).getTime() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
            } else {
              return new Date(new Date(paymentDate.value).getTime() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
            }
          }
          return null;
     }

     const installmentEnterData = (index,type) => {
        let installments = addInvoiceBodyParam.value.installments;
        let rulesKey     = Number(index).toString();
        if(installments){
          if(type == 'cib'){
            if(installments[rulesKey].installment_cib_receipt.length > 0){
              let allCibErrIndexes = rules.value.installment_cib_receipt.index;
              console.log(allCibErrIndexes);
              removeIndex(index,allCibErrIndexes);
            }
          }
          if(type == 'installment_amount'){
            if(installments[index].installment_amount > 0){
              let allAmountErrIndexes = rules.value.installment_amount.index;
              removeIndex(index,allAmountErrIndexes);
            }
          }
          if(type == 'payment_date'){
            if(installments[index].installment_payment_date.length > 0){
              let allPaymentErrIndexes = rules.value.installment_payment_date.index;
              removeIndex(index,allPaymentErrIndexes);
            }
          }

          let tinsAmount =0;
          installments.forEach(function(ins) {
            tinsAmount += parseFloat(ins.installment_amount);
          });

          if(tinsAmount != addInvoiceBodyParam.value.amount){
            rules.value.installment_amount_total.show = false;
            rules.value.installment_amount_total.type = '';
          }


        }

    }

    const removeIndex = (selectedIndex: string, allErrIndexes: string[] = []) => {
      if(allErrIndexes){
          const indexPosition = allErrIndexes.indexOf(selectedIndex.toString());
          if (indexPosition > -1) {
            allErrIndexes.splice(indexPosition, 1); // Remove the element at indexPosition
          }
      }
    }

    const validateAmount = (event, index) => {
      let value = event.target.value;

      const decimalParts = value.split('.');
      if (decimalParts.length > 2) {
        decimalParts.splice(2, decimalParts.length - 2);
        value = decimalParts.join('.');
      }

      if (value.startsWith('.')) {
        value = '0' + value;
      }

      // Remove all non-numeric characters except dot
      value = value.replace(/[^0-9.]/g, '');

      // Split the value on the decimal point
      const parts = value.split('.');

      // Limit to 9 digits before the decimal point
      if (parts[0].length > 9) {
        parts[0] = parts[0].substring(0, 9);
      }

      // Limit to 2 digits after the decimal point
      if (parts[1]?.length > 2) {
        parts[1] = parts[1].substring(0, 2);
      }

      // Rejoin the parts
      event.target.value = parts.join('.');

      // Update the v-model value
      addInvoiceBodyParam.value.installments[index].installment_amount = event.target.value;
    }
    

    return {
      closeModal,
      fileError,
      disabledByDefault,
      isExistCustomer,
      checkPhoneNo,
      paymentDate,
      insurancePayment,
      toNewEmail,
      toPhoneNumber,
      isErrorEmail,
      toFirstDl,
      toDB,
      toCarValue,
      toPaymentType,
      toPolicyEndDate,
      toEmail,
      toLanguage,
      toOrderDescription,
      toExpiryDate,
      toCancel,
      toAmount,
      toExpectedPolicy,
      toDriverNationality,
      nameTransferType,
      passValueExpiryDate,
      focusField,
      roles,
      currentyear,
      dobYear,
      currentDay,
      currentMonth,
      day,
      addDayDate,
      isDecimalAmountValid,
      checkdateLeadDriverDob,
      isDecimalValid,
      loading,
      clearHomeDrivingExperience,
      clearUaeDrivingExperience,
      resetClaims,
      disabledDate,
      disableDateOne,
      yearMinuseighteen,
      addInvoiceBodyParam,
      addInvoice,
      handleFileUpload,
      expiryDate,
      getExpiryDateTime,
      resetSalesAgent,
      errMsg,
      showInvoiceDom,
      onSalesAgentChange,
      amendInvoiceInformation,
      disableSaveButton,
      getPolicyEndDate,
      getAmendmentDetails,
      nationality,
      dlCountry,
      uaeExperience,
      homeExperience,
      ncc,
      getNationality,
      getVehicleUse,
      getThirdParty,
      getPolicyExpiry,
      getDOB,
      getHomeDrivingExperience,
      getUaeDrivingExperience,
      getDlNationality,
      getNoClaim,
      rules,
      validateAddInvoice,
      setPaymentMode,
      countryCodes,
      onCountryCodeChange,
      phoneNumberValidation,
      otherGenders,
      paymentMethodLabel,
      changeIsSplitPayment,
      onChangeSplitPaymentInstallments,
      installmentCountText,
      isSplitPaymentDatePickerDisabled,
      splitPaymentDatePickerMin,
      installmentEnterData,
      validateAmount,
      common
    };
  }
});
