
import { computed, defineComponent, getCurrentInstance, onMounted, ref } from "vue";
import { useStore } from "vuex";
import router from "@/router";
import { onBeforeRouteLeave } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";
import EmailService from "@/core/services/car/EmailService";
import { notif } from '@/store/stateless/store';
import { ElNotification } from "element-plus";
import InvoiceService from "@/core/services/car/InvoiceService";

export default defineComponent({
  props: {
    isError: {
      type: Boolean,
      required: true,
      default: false,
    },
    moduleType: {
      type: String,
      required: false,
      default: "invoice",
    },
    isAmend: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup(props) {
    const store = useStore();
    console.log("props.isError", props.isError);
    let loading = ref(false);

    const openModal = computed(() => {
      return store.getters.getInvoiceModal;
    });

    const openModalValue = computed(() => {
      return store.getters.getInvoiceModalValue;
    });

    // const openModalLoading = computed(() => {
    //    return store.getters.getModalLoading
    // })

    const redirectToLeadDetails = () => {
      store.commit(Mutations.SET_INVOICE_MODAL);

      if(props.isAmend) {
        router.push({ name: "invoice-list" })
      } else {
        // console.log({
        //   modal: openModalValue.value
        // })
        InvoiceService.makeInvoiceEvent({
          driver_id: router.currentRoute.value.params.quoteId,
          type: 'car'
        })
        
        // if (props.moduleType == "manual-order") {
        //   router.push({ name: "manual-order-view" });
        // } else {
        //   router.push({
        //     name: "customer-details",
        //     params: { customerId: openModalValue.value.customer_id },
        //   });
        // }
      }
    };

    const redirectToWhatsapp = () => {
      let message =
        "This is the your payment url:  " + openModalValue.value.payment_link + " -BuyAnyInsurance";
      window.open(
        "https://wa.me/" +
          openModalValue.value.phone_number.replace('+', '') +
          "?text=" +
          message,
        "_target"
      );

      redirectToLeadDetails();
    };

    const sendEmailItTeam = () => {
      loading.value = true;

      let data:any = {
        customer_id: openModalValue.value.customer_id,
        has_duplicate_invoice: true
      }

      if(openModalValue.value.hasError) {
        delete data.has_duplicate_invoice
      }

      EmailService.sendEmailItTeam(data)
        .then((data) => {
          loading.value = false;
          notif.simple('', 'success', 'Email has been sent successfully!');
          redirectToLeadDetails();

        })
        .catch(() => {
          loading.value = false;
          notif.simple('', 'warning', 'Email has not been sent!');
          redirectToLeadDetails();
        });
      
    };

    const sendEmailPaymentCustomer = () => {
      store.commit(Mutations.SET_INVOICE_MODAL);
      if(props.isAmend) {
        router.push({ name: "invoice-list" })
      } else {
        loading.value = true;
        EmailService.sendEmailPaymentCustomer({
          invoice_id: openModalValue.value.id,
        })
          .then((data) => {
            loading.value = false;
            notif.simple('', 'success', 'Email has been sent successfully!');
            redirectToLeadDetails();
          })
          .catch(() => {
            loading.value = false;
            notif.simple('', 'warning', 'Email has not been sent!');
            redirectToLeadDetails();
          });
      }
    };

    onBeforeRouteLeave(() => {
      ElNotification.closeAll();
    })

    const inst = getCurrentInstance()
    const toFirst = () => {
      setTimeout(() => {
          const okRef:any = inst?.refs.okRef
          okRef.focus()
      }, 500);
    }

    return {
      toFirst,
      ElNotification,
      loading,
      sendEmailPaymentCustomer,
      sendEmailItTeam,
      redirectToWhatsapp,
      redirectToLeadDetails,
      openModal,
      openModalValue,
    };
  },
});
